"use client";

import { motion, AnimatePresence } from "framer-motion";
import { usePathname } from 'next/navigation';

const pageVariants = {
  initial: { scale: 1.1, opacity: 0, zIndex: 0 },
  animate: { scale: 1, opacity: 1, zIndex: 1 },
  exit: { scale: 0.9, opacity: 0, zIndex: 0 },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

export default function Template({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={pathname}
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={pageTransition}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
